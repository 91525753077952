import { StrapiImage } from "@/interface/strapi";
import Image from "next/image";
import { useEffect, useState } from "react";
interface KleverImagePorps {
  desktopImage: StrapiImage;
  mobileImage?: StrapiImage;
  alt: string;
  fill: boolean;
  perspective: boolean;
}
const isMobileDevice = () => {
  return window.innerWidth <= 768;
};

const KlevrImage = (props: KleverImagePorps) => {
  const [isMobile, setIsMobile] = useState(false);
  useEffect(() => {
    setIsMobile(isMobileDevice());
    const handleResize = () => {
      setIsMobile(isMobileDevice());
    };
    window.addEventListener("resize", handleResize);
    // Cleanup function
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const { desktopImage, mobileImage, perspective } = props;
  const { fill } = props;
  return (
    <>
      {mobileImage && mobileImage?.url?.length > 0 && isMobile ? (
        <>
          {fill ? (
            <Image
              src={mobileImage?.url}
              alt={props.alt}
              layout="fill"
              objectFit={perspective ? "fill" : "cover"}
            />
          ) : (
            <Image
              src={mobileImage?.url}
              alt={props.alt}
              height={mobileImage?.height}
              width={mobileImage?.width}
            />
          )}
        </>
      ) : (
        <>
          {fill ? (
            <Image
              src={desktopImage?.url}
              alt={props.alt}
              layout="fill"
              objectFit={perspective ? "fill" : "cover"}
            />
          ) : (
            <Image
              src={desktopImage?.url}
              alt={props.alt}
              height={desktopImage?.height}
              width={desktopImage?.width}
            />
          )}
        </>
      )}
    </>
  );
};
export default KlevrImage;
