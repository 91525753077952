/* eslint-disable react/no-children-prop */
"use client";
import { Carousel } from "@/interface/homeCarousel";
import { commonFetch } from "@/utils/commonFetch";
import Image from "next/image";
import { useEffect, useState } from "react";
import "swiper/css";
import { Swiper, SwiperSlide } from "swiper/react";
import KlevrImage from "../shared/image/KlevrImage";
import ReactMarkdown from "react-markdown";

export default function Home() {
  const [carousels, setCarousels] = useState<Carousel[]>([]);
  const [swiper, setSwiper] = useState<any>();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await commonFetch.get("home-page");
        setCarousels(response?.carousels);
      } catch (error) {
        console.error("Error fetching data", error);
      }
    };

    fetchData();
  }, []);

  return (
    <div className="h-full md:h-[92vh] overflow-hidden relative z-0">
      {carousels?.length > 0 && (
        <div className="content-wrapper h-full relative">
          <Swiper
            spaceBetween={0}
            slidesPerView={1}
            slidesPerGroup={1}
            loop={true}
            onSwiper={(swiper: any) => setSwiper(swiper)}
          >
            {carousels?.map((slide, index) => (
              <SwiperSlide key={index}>
                <div className="h-screen w-full">
                  <Image
                    src="/rec-top.png"
                    className="w-full absolute z-10 top-[0%] h-[35%]"
                    alt="My SVG"
                    width={50}
                    height={50}
                  />
                  <Image
                    src="/rec-bot.png"
                    className="w-full absolute z-10 top-[45%] h-[55%] md:-mt-[15px] md:h-[50%]"
                    alt="My SVG"
                    width={50}
                    height={50}
                  />
                  <KlevrImage
                    perspective={false}
                    desktopImage={slide?.desktopImage}
                    mobileImage={slide?.mobileImage}
                    alt="Logo"
                    fill={true}
                  />

                  <div className="xl:top-[55%] md:top-[65%] top-[60%] absolute z-10 lg:ml-[60px] ml-4 slider-details slider-desc">
                    <span className="title absolute -mt-[10px]">
                      {slide?.title}
                    </span>
                    <br />
                    <a href={slide?.link}>
                      <span className="home-links text-left">
                        <ReactMarkdown children={slide?.description} />
                      </span>
                    </a>
                  </div>
                  <div className="flex justify-start absolute w-full z-10 md:top-[80%] top-[85%] lg:ml-[65px] ml-4 slider-details">
                    <div
                      className="prev cursor-pointer text-white"
                      onClick={() => swiper?.slidePrev()}
                    >
                      <Image
                        src="/assets/icons/prev-arrow.svg"
                        alt="My SVG"
                        width={50}
                        height={50}
                      />
                    </div>
                    <div
                      className="next cursor-pointer text-white ml-5"
                      onClick={() => swiper?.slideNext()}
                    >
                      <Image
                        src="/assets/icons/next-arrow.svg"
                        alt="My SVG"
                        width={50}
                        height={50}
                      />
                    </div>
                  </div>
                </div>
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
      )}
    </div>
  );
}
